<template>
  <div>
    <div class="d-flex">
      <div class="card">
        <div class="card-body p-0">
          <div ref="dp" :data-date="date"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Datepicker } from 'vanillajs-datepicker';

export default {
  name: 'DatePickerField',
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      datePicker: null,
      dateFormat: 'dd-mm-yyyy',
    };
  },
  mounted() {
    this.datePicker = new Datepicker(this.$refs.dp, {
      buttonClass: 'btn',
      format: 'dd-mm-yyyy',
      minDate: this.date,
      maxView: 0,
    });
    this.$refs.dp.addEventListener('changeDate', (e) => {
      const { detail: { datepicker } } = e;
      const newDate = datepicker.getDate(this.dateFormat);
      this.$emit('select', newDate);
    });
  },
};
</script>

<style lang="scss">
@import "@/scss/vars.scss";

.datepicker-picker {
  background-color: transparent;
}

.datepicker-controls .btn.view-switch {
  pointer-events: none;
}

.datepicker-cell.selected, .datepicker-cell.selected:hover {
  background-color: #0466c8;
}
</style>
